import React, { useMemo, useRef, useState, useEffect } from 'react'
import clsx from 'clsx'

import { ButtonBase, makeStyles, Theme } from '@material-ui/core'

import Modality from '@ui/Modality'
import Typography from '@ui/Typography'
import { ModalityType } from '@ui/Modality/Modality'
import useResponsively from '@hooks/useResponsively'
import useLimit from '@utils/useLimit'

import SnackToast from '@components/SnackToast';
import BookmarkIcon from '@components/BookmarkIcon'

import useBookmark from '@hooks/useBookmark';


type StyleProps = {
	isSmall: boolean;
	imgsrc?: string | undefined;
	color?: string;
}
const useStyles = makeStyles<Theme, StyleProps>(theme => ({
	card: ({ imgsrc, color }) => ({
		boxShadow: theme.elevation[1],
		borderRadius: theme.spacing(2.5),
		minHeight: imgsrc ? theme.spacing(41) : theme.spacing(28.5),
		position: 'relative',
		overflow: 'hidden',
		maxHeight: imgsrc ? theme.spacing(41) : theme.spacing(28.5),

		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: imgsrc ? 'end' : 'center',
		backgroundImage: `url(${imgsrc})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundColor: 'white',

		'&:before': imgsrc ? {
			content: '""',
			position: 'absolute',
			display: 'block',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		} : {},

		'&:after': !imgsrc ? {
			content: '""',
			position: 'absolute',
			display: 'block',
			zIndex: 0,
			top: 0,
			left: 0,
			right: 0,
			background: color ?? theme.palette.color.purple[40],
			height: theme.spacing(2),
		} : {},
	}),
	heading: {
		marginBottom: theme.spacing(2),
		maxHeight: theme.spacing(8.75),
		minHeight: theme.spacing(8.75),
		overflow: 'hidden',
	},
	subtitle:{
		marginBottom: theme.spacing(2),
	},
	container:{
		display: 'flex',
		justifyContent: 'space-between',
	},
	modality:{
		marginBottom:theme.spacing(0)
	},
	content: ({ imgsrc }) => ({
		textAlign: 'left',
		width: '100%',
		borderRadius: theme.spacing(0,2.5,0,0),
		zIndex: 1,
		minHeight: !imgsrc ? theme.spacing(19.5) : theme.spacing(26.25),
		maxHeight: !imgsrc ? theme.spacing(19.5) : theme.spacing(26.25),
		display: 'flex',
		padding: imgsrc ? theme.spacing(3) : theme.spacing(0,3,3,3),
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: imgsrc ? theme.palette.color.white : 'transparent',
	}),
}), { name: 'ContentCard' });


type JSXChildNode <T> = React.ReactElement<T> | React.ReactElement<T>[];
export type ContentCardProps = {
	title: string | JSXChildNode<typeof Typography>;
	id?:string;
	mode?: ModalityType;
	image?: string;
	color?: string;
	subtitle?:string;
	isBookmark?: boolean;
	bookmarkId?:string;
	source?:string;
	className?: string;
	onClick?: (a:any) => void;
}

const ContentCard: React.FC<ContentCardProps> = (props) => {
	const {
		title,
		id,
		mode = 'Video',
		image,
		color,
		source,
		subtitle,
		isBookmark,
		bookmarkId,
		onClick,
		className,
	} = props;
	const cardRef = useRef<HTMLElement>();
	const bounds = useResponsively(cardRef);
	const {individualLearnContent,handleBookmarkClick,handleUnBookmarkClick,snack} = useBookmark({isBookmark:isBookmark,modality:mode, id:id,bookmarkId:bookmarkId});

	//	this number, '560' is the breakpoint width of the element, not the window.
	const isSmall = useMemo(() => (bounds?.width ?? 0) < 560, [bounds?.width]);

	const [imgsrc, setImgSrc] = useState(image);
	useEffect(() => {
		const img = new Image();
		//@ts-ignore
		img.src = image;
		//@ts-ignore
		img.onload = () => setImgSrc(image)

	}, [])

	
	const handleIconClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		individualLearnContent.isBookmark ? handleUnBookmarkClick() : handleBookmarkClick();
	};
	

	const abbrevTitle = useLimit(title as any, 50)
	const classes = useStyles({ imgsrc, color, isSmall });

	return (
		<>
		{/* //	@ts-ignore the buttonRef deprecation */}
			<ButtonBase data-test='ContentCard' className={clsx(classes.card, className)} buttonRef={cardRef} onClick={(...args) => onClick?.(...args)}>
				<div className={classes.content}>
					<Typography data-test='ContentCard-title' type='body' variant='primary' className={classes.heading}>
						{abbrevTitle}
					</Typography>
					<Typography data-test='ContentCard-subtitle' type='body' variant='secondary' className={classes.subtitle}>
						{mode === 'Article' ? source : subtitle}
					</Typography>

					<div className={classes.container}>
						<Modality className={classes.modality} typographyVariant='secondary' mode={mode} />
						{
							(mode !== 'Activity') ? <BookmarkIcon dataTest={individualLearnContent.isBookmark ? 'ContentCard-bookmarked' : 'ContentCard-not-bookmarked' } onClick={handleIconClick} isBookmark={individualLearnContent.isBookmark}/> : null
						}
					</div>
				</div>
			</ButtonBase>

			<SnackToast
			message={  
				<Typography disableMargin type='body' variant='secondary'>
				Saved to Wellbook
				</Typography>
			}
			isOpen={ snack.isSnackOpen }
			onClose={ snack.closeSnack }
			/>
		</>
	)
}

export default ContentCard